import "./desktop.scss";

import { Fragment, memo, useCallback, useState } from "react";
import Header from "components/header/Header";
import Footer from "components/footer";
import { HiMagnifyingGlass, HiMinus, HiPlus, HiXMark } from "react-icons/hi2";
import SideMenu from "components/SideMenu";
import { listFAQs, resourceCards } from "./const";
import { highlightText } from "assets/ultils/highlightText";
import { useNavigate } from "react-router-dom";
import { sideBarMenus } from "assets/common/resources";

const GettingStarted = () => {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value);
    },
    []
  );

  const handleResourceLink = (key: number, link?: string) => {
    if (link) navigate(link);
  };

  return (
    <div className="gettingStartedPage">
      <Header autoScrolled />

      {/* Header Section */}
      <div className="gettingStartedPage-wrapper">
        <div className="gettingStartedPage-headerWrapper">
          <div className="headerTitle">
            {highlightText("Hello, How can we help?", searchQuery)}
          </div>
          <div className="gettingStartedPage-input">
            <HiMagnifyingGlass size={20} />
            <input
              aria-label="Search input"
              placeholder="Search using keyword..."
              value={searchQuery}
              onChange={handleInputChange}
            />

            <HiXMark
              size={20}
              className={searchQuery ? "showCancel" : "removeCancel"}
              onClick={() => setSearchQuery("")}
            />
          </div>
        </div>
      </div>

      <div className="gettingStartedPage-mainContent">
        <SideMenu
          isNavigate
          sideBarMenus={sideBarMenus}
          searchQuery={searchQuery}
        />

        <main className="gettingStartedPage-content">
          <header className="gettingStartedPage-contentHeader">
            <div className="headerWrap">
              <h1>{highlightText("Getting Started", searchQuery)}</h1>
              <p>
                {highlightText(
                  "Welcome to Swim Sync! Whether you're a swimming school administrator, instructor, parent, or student, this section will help you dive right in.",
                  searchQuery
                )}
              </p>
            </div>
            <img src="/svgs/gettingStartedLogo.svg" alt="gettingStartedLogo" />
          </header>
          <section className="gettingStartedPage-resourceCards">
            {resourceCards.map((card, key) => (
              <button
                key={key}
                className="gettingStartedPage-resourceCard"
                onClick={() => handleResourceLink(key, card?.link)}>
                <h3>{highlightText(card.title, searchQuery)}</h3>
                <div className="gettingStartedPage-resourceLink">
                  {highlightText(card.linkText, searchQuery)}
                </div>
                <p>{highlightText(card.description, searchQuery)}</p>
              </button>
            ))}
          </section>
          <section className="gettingStartedPage-faqSection">
            <div className="faqSectionHead">
              <h2>{highlightText("FAQs", searchQuery)}</h2>
              <p>
                {highlightText(
                  "Answers to our most commonly asked questions.",
                  searchQuery
                )}
              </p>
            </div>

            <div className="gettingStartedPage-faqList">
              {listFAQs?.map((faq, key) => (
                <Fragment key={key}>
                  <input id={`${key}`} type="checkbox" />
                  <label htmlFor={`${key}`}>
                    <div className="FAQs__list__item">
                      <h3 className="FAQs__list__item-title cursor">
                        {highlightText(faq?.question, searchQuery)}
                      </h3>
                      <HiPlus className="cursor plus" size={20} />
                      <HiMinus className="cursor minus" size={20} />
                    </div>
                    <ul className="faqList__answer">
                      {faq?.answer?.map((answerDt: any, answerKey) => {
                        if (typeof answerDt === "string")
                          return (
                            <li key={answerKey}>
                              {highlightText(answerDt, searchQuery)}
                            </li>
                          );

                        if (answerDt?.description)
                          return (
                            <p key={answerKey}>
                              {highlightText(
                                answerDt?.description,
                                searchQuery
                              )}
                            </p>
                          );

                        if (answerDt?.steps)
                          return (
                            <Fragment key={answerKey}>
                              <p>
                                {highlightText(answerDt?.title, searchQuery)}
                              </p>
                              <ul>
                                {answerDt?.steps?.map(
                                  (step: string, stepKey: number) => (
                                    <li key={stepKey}>
                                      {highlightText(step, searchQuery)}
                                    </li>
                                  )
                                )}
                              </ul>
                            </Fragment>
                          );

                        if (answerDt?.note)
                          return (
                            <p key={answerKey}>
                              <strong>
                                {highlightText("Note:", searchQuery)}
                              </strong>
                              {highlightText(answerDt?.note, searchQuery)}
                            </p>
                          );

                        return <></>;
                      })}
                    </ul>
                  </label>
                </Fragment>
              ))}
            </div>
          </section>
        </main>
      </div>

      <Footer />
    </div>
  );
};

export default memo(GettingStarted);

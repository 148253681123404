import "./desktop.scss";

import Footer from "components/footer";
import Header from "components/header/Header";
import { memo, useMemo, useState } from "react";
import { setupInstructions, blogList } from "./const";
import { HiArrowRight } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

const CaseStudies = () => {
  const navigate = useNavigate();

  const [toggleAll, setToggleAll] = useState(false);

  const brandingList = useMemo(() => {
    const logos =
      setupInstructions?.map((setupInstruction) => setupInstruction?.logo) ||
      [];

    // If the length is less than 20, replicate the array elements
    while (logos.length < 20) {
      logos.push(...logos.slice(0, 20 - logos.length));
    }

    return logos;
  }, []);

  const handleNavigateLink = (link: string) => {
    navigate(link);
  };

  return (
    <>
      <div className="caseStudiesPage">
        <Header autoScrolled />

        <div className="caseStudiesPage-wrapper">
          {toggleAll ? (
            <>
              <div className="caseStudiesPage-header-toggle">
                <h1>
                  Case Study <br /> Revolutionizing Swimwear
                  <br /> for Competitive Athletes
                </h1>
              </div>

              <div className="caseStudiesPage-branding">
                {brandingList?.map((branding, key) => (
                  <img
                    key={key}
                    width={116}
                    height={43}
                    className="titleImage"
                    src={branding}
                    alt={branding}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className="caseStudiesPage-header">
              <h1>Case Studies</h1>

              <button onClick={() => setToggleAll((pre) => !pre)}>
                <span>View all</span>

                <HiArrowRight />
              </button>
            </div>
          )}

          <div className="caseStudiesPage-content">
            {setupInstructions?.map((setupInstruction, key) => (
              <div key={key} className={key === 0 ? "firstItem" : "item"}>
                <img
                  className="titleImage"
                  src={setupInstruction?.titleImage}
                  alt={setupInstruction?.titleImage}
                />

                <div className="itemWrap">
                  <img
                    className="logo"
                    src={setupInstruction?.logo}
                    alt={setupInstruction?.titleImage}
                  />

                  <p>{setupInstruction?.description}</p>

                  <button
                    className="btnWrap"
                    onClick={() => handleNavigateLink(setupInstruction?.link)}>
                    <p>{setupInstruction?.linkText}</p>

                    <HiArrowRight />
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="caseStudiesPage-blog">
            <div className="blogHead">
              <h1>Blog</h1>

              <button>
                <span>View all</span>

                <HiArrowRight />
              </button>
            </div>

            <div className="blogList">
              {blogList?.map((setupInstruction, key) => (
                <div key={key} className="item">
                  <img
                    className="titleImage"
                    src={setupInstruction?.titleImage}
                    alt={setupInstruction?.titleImage}
                  />

                  <p>{setupInstruction?.description}</p>

                  <button
                    className="btnWrap"
                    onClick={() => handleNavigateLink(setupInstruction?.link)}>
                    <p>{setupInstruction?.linkText}</p>

                    <HiArrowRight />
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className="caseStudiesPage-requestContent">
            <div className="content">
              <h1>
                Ready to elevate your swim school management to Olympic levels?
                Experience the Swim Sync difference today!
              </h1>

              <button
                onClick={() => {
                  // Mở widget khi nhấn nút
                  window.Calendly.showPopupWidget(
                    "https://calendly.com/swimsync-support/swim-sync-demo-booking"
                  );
                }}>
                Request a Demo
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default memo(CaseStudies);

export const resourceCards = [
  {
    title: "Downloadable Documents",
    linkText: "Swim Sync Quick Start Guide",
    link: "",
    description: "",
  },
  {
    title: "Downloadable Documents",
    linkText: "Administrator's Checklist for New Swimming Schools",
    link: "",
    description: "",
  },
  {
    title: "Downloadable Documents",
    linkText: "Instructor Handbook: Best Practices for Digital Lesson Planning",
    link: "",
    description: "",
  },
  {
    title: "Downloadable Documents",
    linkText: "Parent's Guide to Swim Level Progression",
    link: "",
    description: "",
  },
  {
    title: "Downloadable Documents",
    linkText: "Student Goal-Setting Worksheet",
    link: "",
    description: "",
  },
  {
    title: "Downloadable Documents",
    linkText: "Swim Sync Security and Privacy Policy",
    link: "",
    description: "",
  },
  {
    title: "Downloadable Documents",
    linkText: "Mobile App User Manual",
    link: "",
    description: "",
  },
];

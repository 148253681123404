import "./desktop.scss";

import { Fragment, memo } from "react";
import ListIcon from "./ListIcon";
import CustomerSupportIcon from "./CustomerSupportIcon";
import UpdateIcon from "./UpdateIcon";
import SlideUpOnScroll from "components/SlideUpOnScroll";

interface Props {
  isToggle?: boolean;
}

const compareList = [
  {
    name: "Management Features",
    data: [
      {
        name: "Student Management",
        toolTip:
          "Maintain detailed records of all students. Centralised management of student profiles, including personal details, attendance, progress and health information.",
        option: true,
      },
      {
        name: "Parent/Guardian Management",
        toolTip: "Parent/Guardian Management.",
        option: true,
      },
      {
        name: "Location Management",
        toolTip: "Location Management.",
        option: true,
      },
      {
        name: "Term and Holiday Management",
        toolTip: "Term and Holiday Management.",
        option: true,
      },
      {
        name: "Program and Levels Management",
        toolTip: "Program and Levels Management.",
        option: true,
      },
    ],
  },
  {
    name: "Financial Features",
    data: [
      {
        name: "Payment and Billing Management",
        toolTip: "Payment and Billing Management.",
        option: true,
      },
      {
        name: "Billing and Transaction Management",
        toolTip: "Billing and Transaction Management.",
        option: true,
      },
    ],
  },
  {
    name: "Portal Access",
    data: [
      {
        name: "Command Center",
        toolTip: "Command Center.",
        option: true,
      },
      {
        name: "Swim Portal",
        toolTip: "Swim Portal.",
        option: true,
      },
    ],
  },
];

const renderIcon = (checked?: boolean) => {
  if (checked)
    return (
      <div className="icon__checked">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none">
          <path
            d="M4.5 12.75L10.5 18.75L19.5 5.25"
            stroke="#111827"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );

  return (
    <div className="icon__unchecked">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
          d="M19.5 12H4.5"
          stroke="#111827"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

const Compare = ({ isToggle }: Props) => {
  return (
    <div className="compareAllFeatures">
      <SlideUpOnScroll>
        {isToggle ? (
          <div className="compareStaticList">
            <div className="item">
              <div className="text1">Single Location</div>
              <div className="text2">$3,049</div>
              <div className="text3">/year/location</div>
            </div>
            <div className="item">
              <div className="text1">2-5 Locations</div>
              <div className="text2">$2,744</div>
              <div className="text3">/year/location</div>
            </div>
            <div className="item">
              <div className="text1">6-10 Locations</div>
              <div className="text2">$2,438</div>
              <div className="text3">/year/location</div>
            </div>
          </div>
        ) : (
          <div className="compareStaticList">
            <div className="item">
              <div className="text1">Single Location</div>
              <div className="text2">$299</div>
              <div className="text3">/mo/location</div>
            </div>
            <div className="item">
              <div className="text1">2-5 Locations</div>
              <div className="text2">$269</div>
              <div className="text3">/mo/location</div>
            </div>
            <div className="item">
              <div className="text1">6-10 Locations</div>
              <div className="text2">$239</div>
              <div className="text3">/mo/location</div>
            </div>
          </div>
        )}
      </SlideUpOnScroll>

      <SlideUpOnScroll>
        <div className="compareHeader">
          <span>One Flat Price for Every Feature </span>
          <span>Swim Sync Offers!</span>
        </div>
      </SlideUpOnScroll>

      <div className="compareWrap">
        {compareList?.map((item, key) => (
          <Fragment key={key}>
            <SlideUpOnScroll>
              <div className="head">{item?.name}</div>
              {item?.data?.map((dt, i) => (
                <div className="row" key={i}>
                  <div className="name">{dt.name}</div>
                  <div className="displayCheck">{renderIcon(dt.option)}</div>
                </div>
              ))}
            </SlideUpOnScroll>
          </Fragment>
        ))}
      </div>

      <SlideUpOnScroll>
        <div className="compareExtra">
          <div className="item">
            <div className="iconWrap">
              <ListIcon />
            </div>

            <div className="text">Setup and Onboarding</div>
          </div>
          <div className="item">
            <div className="iconWrap">
              <CustomerSupportIcon />
            </div>

            <div className="text">24/7 Customer Support</div>
          </div>
          <div className="item">
            <div className="iconWrap">
              <UpdateIcon />
            </div>

            <div className="text">Regular Updates and New Features</div>
          </div>
        </div>
      </SlideUpOnScroll>
    </div>
  );
};

export default memo(Compare);

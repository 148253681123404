import "./desktop.scss";

import Footer from "components/footer";
import Header from "components/header/Header";
import { memo, useCallback, useMemo, useState } from "react";
import { setupInstructions } from "./const";
import SideMenu from "components/SideMenu";
import { HiMagnifyingGlass, HiXMark } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { highlightText } from "assets/ultils/highlightText";
import AppModal from "components/AppModal";
import { RiErrorWarningFill } from "react-icons/ri";
import CryptoJS from "crypto-js";

const QuickSetupGuide = () => {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    },
    []
  );

  const generateHmacHash = (email: string, timestamp: number) => {
    const secretKey = "swimsynlandingpage";
    const message = `${email}:${timestamp}`;
    const hmac = CryptoJS.HmacSHA256(message, secretKey);
    return hmac.toString(CryptoJS.enc.Hex);
  };

  const handleSubmit = useCallback(async () => {
    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
    const timestamp = Date.now();
    const hash = generateHmacHash(email, timestamp);
    try {
      const response = await fetch(
        `https://swim-sync-api.advancer.asia/api/v1/landing-pages/send-welcome-email-to-subscriber`,
        {
          method: "POST",
          body: JSON.stringify({
            email,
            hash,
            timestamp,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      if (result.data) {
        setOpenModalSuccess(true);
        setEmail("");
      } else {
        setOpenModalError(true);
        setErrorMessage(result.message);
      }
    } catch (error) {
      console.error("error: ", error);
    }
  }, [email]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value);
    },
    []
  );

  const handleNavigateLink = (link: string) => {
    navigate(link);
  };

  const handleScrollToSection = (id: string) => {
    const element = document.getElementById(id);

    if (element) {
      const headerOffset = 100; // Adjust this value based on your header height
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const sideBarMenus = useMemo(() => {
    return setupInstructions?.map((setupInstruction, key) => {
      const id = `section-${key}`;

      return { title: setupInstruction?.title, link: id, icon: "" };
    });
  }, []);

  return (
    <>
      {/* Success Modal */}
      <AppModal
        open={openModalSuccess}
        onClose={() => setOpenModalSuccess(false)}
      >
        <div className="receiveMailModal">
          <div className="header">
            <img src="/images/receive-mail.png" alt="" />
            <div
              className="loginModal-header__close"
              onClick={() => setOpenModalSuccess(false)}
            >
              <HiXMark size={24} />
            </div>
          </div>
          <div className="content">
            Thank you for subscribing! Check your inbox for your Splash of
            Welcome!
          </div>
        </div>
      </AppModal>

      {/* Error Modal */}

      <AppModal
        open={openModalError}
        onClose={() => setOpenModalSuccess(false)}
      >
        <div className="receiveMailModal">
          <div className="header">
            <RiErrorWarningFill size={54} className="icon" />
            <div
              className="loginModal-header__close"
              onClick={() => setOpenModalError(false)}
            >
              <HiXMark size={24} />
            </div>
          </div>
          <div className="content">{errorMessage}</div>
        </div>
      </AppModal>

      <div className="quickSetupGuidePage">
        <Header autoScrolled />

        <div className="quickSetupGuidePage-wrapper">
          <div className="quickSetupGuidePage-header">
            <div className="quickSetupGuidePage-headTitle">
              <span
                className="quickSetupGuidePage-headSection"
                onClick={() => handleNavigateLink("/getting-started-faqs")}
              >
                {highlightText("Getting Started", searchQuery)}
              </span>{" "}
              /
              <span className="quickSetupGuidePage-current">
                {highlightText("Quick Setup Guide", searchQuery)}
              </span>
            </div>
            <div className="quickSetupGuidePage-searchBar">
              <HiMagnifyingGlass size={20} />

              <input
                aria-label="Search input"
                placeholder="Search using keyword..."
                value={searchQuery}
                onChange={handleInputChange}
              />

              <HiXMark
                size={20}
                className={searchQuery ? "showCancel" : "removeCancel"}
                onClick={() => setSearchQuery("")}
              />
            </div>
          </div>

          <div className="quickSetupGuidePage-content">
            <SideMenu
              sideBarMenus={sideBarMenus}
              type="content"
              searchQuery={searchQuery}
              onChange={handleScrollToSection}
            />

            <div className="quickSetupGuidePage-mainContent">
              <h1 className="quickSetupGuidePage-title">
                {highlightText("Quick Setup Guide", searchQuery)}
              </h1>
              <h2 className="quickSetupGuidePage-subtitle">
                {highlightText("Log into Your Admin Dashboard", searchQuery)}
              </h2>
              <p className="quickSetupGuidePage-description">
                {highlightText(
                  "Access the system to begin the setup process.",
                  searchQuery
                )}
              </p>
              <ul className="quickSetupGuidePage-steps">
                <li className="quickSetupGuidePage-step">
                  {highlightText(
                    "Open your browser and go to the Swimming School Management System login page.",
                    searchQuery
                  )}
                </li>
                <li className="quickSetupGuidePage-step">
                  {highlightText(
                    "Enter your provided username and password to log in.",
                    searchQuery
                  )}
                </li>
                <li className="quickSetupGuidePage-step">
                  {highlightText(
                    "If this is your first time logging in, you may be prompted to change your password.",
                    searchQuery
                  )}
                </li>
              </ul>

              <div className="quickSetupGuidePage-loginSampleImage">
                <img
                  src="/images/loginSampleImage.png"
                  alt="loginSampleImage"
                />
              </div>

              <div className="quickSetupGuidePage-sections">
                {setupInstructions?.map((setupInstruction, key) => (
                  <div
                    className="quickSetupGuidePage-section"
                    key={key}
                    id={`section-${key}`}
                  >
                    <h2 className="quickSetupGuidePage-sectionTitle">
                      {highlightText(setupInstruction?.title, searchQuery)}
                    </h2>
                    <p className="quickSetupGuidePage-sectionDescription">
                      {highlightText(
                        setupInstruction?.description,
                        searchQuery
                      )}
                    </p>
                    <ul className="quickSetupGuidePage-sectionList">
                      {setupInstruction?.steps?.map((step, stepKey) => (
                        <li key={stepKey}>
                          {typeof step === "string" ? (
                            highlightText(step, searchQuery)
                          ) : (
                            <>
                              {highlightText(step?.text, searchQuery)}
                              <ul className="quickSetupGuidePage-sectionSubList">
                                {step?.list?.map((item, itemIndex) => (
                                  <li key={itemIndex}>
                                    {highlightText(item, searchQuery)}
                                  </li>
                                ))}
                              </ul>
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>

              <div className="quickSetupGuidePage-feedback">
                <h3>
                  {highlightText("Was this article helpful?", searchQuery)}
                </h3>

                <div className="quickSetupGuidePage-feedbackAction">
                  <button className="quickSetupGuidePage-feedbackBtn">
                    {highlightText("Yes, thanks!", searchQuery)}
                  </button>
                  <button className="quickSetupGuidePage-feedbackBtn">
                    {highlightText("Not really", searchQuery)}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="quickSetupGuidePage-signUp">
            <div className="quickSetupGuidePage-signUpContent">
              <h1>{highlightText("Newsletter Signup", searchQuery)}</h1>

              <p>
                {highlightText(
                  "Stay Updated with Swim School Management Tips!",
                  searchQuery
                )}
              </p>

              <div className="quickSetupGuidePage-signUpInput">
                <div className="input__wrapper">
                  <input
                    type="email"
                    placeholder="Email Address"
                    onChange={handleEmailChange}
                    value={email}
                  />
                  <button className="btn" onClick={handleSubmit}>
                    {highlightText("Subscribe Now", searchQuery)}
                  </button>
                </div>

                {emailError ? (
                  <div className="error-message">
                    {highlightText(
                      "Please enter a valid email address",
                      searchQuery
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default memo(QuickSetupGuide);

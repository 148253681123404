import { sidebarMenu } from "components/SideMenu";

export const sideBarMenus: sidebarMenu[] = [
  {
    title: "Getting Started and FAQs",
    link: "/getting-started-faqs",
    icon: "/svgs/coffee.svg",
  },
  {
    title: "User Guides and Tutorials",
    link: "/user-guides-tutorials",
    icon: "/svgs/document-text.svg",
    subMenus: [
      {
        title: "Administrator's Handbook",
        link: "/user-guides-tutorials/administrators-handbook",
        icon: "",
      },
      {
        title: "Instructor's Toolkit",
        link: "/user-guides-tutorials/instructors-toolkit",
        icon: "",
      },
      { title: "Parent's Guide", link: "", icon: "" },
      { title: "Student's Companion", link: "", icon: "" },
    ],
  },
  {
    title: "Videos",
    link: "/videos",
    icon: "/svgs/video-play.svg",
  },
  {
    title: "Downloadable Documents",
    link: "/downloadable-document",
    icon: "/svgs/document-download.svg",
  },
  {
    title: "Case studies and Blogs",
    link: "/case-studies",
    icon: "/svgs/case-studies.svg",
  },
];

import "./desktop.scss";

import { memo, useCallback, useState } from "react";
import Header from "components/header/Header";
import Footer from "components/footer";
import { HiMagnifyingGlass, HiXMark } from "react-icons/hi2";
import SideMenu from "components/SideMenu";
import { resourceCards } from "./const";
import { highlightText } from "assets/ultils/highlightText";
import { useNavigate } from "react-router-dom";
import { sideBarMenus } from "assets/common/resources";

const AdministratorsHandbook = () => {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value);
    },
    []
  );

  const handleResourceLink = (key: number, link?: string) => {
    if (link) navigate(link);
  };

  return (
    <div className="administratorsHandbookPage">
      <Header autoScrolled />

      {/* Header Section */}
      <div className="administratorsHandbookPage-wrapper">
        <div className="administratorsHandbookPage-headerWrapper">
          <div className="headerTitle">
            {highlightText("Hello, How can we help?", searchQuery)}
          </div>
          <div className="administratorsHandbookPage-input">
            <HiMagnifyingGlass size={20} />
            <input
              aria-label="Search input"
              placeholder="Search using keyword..."
              value={searchQuery}
              onChange={handleInputChange}
            />

            <HiXMark
              size={20}
              className={searchQuery ? "showCancel" : "removeCancel"}
              onClick={() => setSearchQuery("")}
            />
          </div>
        </div>
      </div>

      <div className="administratorsHandbookPage-mainContent">
        <SideMenu
          isNavigate
          sideBarMenus={sideBarMenus}
          searchQuery={searchQuery}
        />

        <main className="administratorsHandbookPage-content">
          <header className="administratorsHandbookPage-contentHeader">
            <div className="headerWrap">
              <h1>{highlightText("Administrator's Handbook", searchQuery)}</h1>
            </div>
          </header>
          <section className="administratorsHandbookPage-resourceCards">
            {resourceCards.map((card, key) => (
              <button
                key={key}
                className="administratorsHandbookPage-resourceCard"
                onClick={() => handleResourceLink(key, card?.link)}>
                <div className="administratorsHandbookPage-resourceLink">
                  {highlightText(card.linkText, searchQuery)}
                </div>
                <p>{highlightText(card.description, searchQuery)}</p>
              </button>
            ))}
          </section>
        </main>
      </div>

      <Footer />
    </div>
  );
};

export default memo(AdministratorsHandbook);

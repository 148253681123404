export const resourceCards = [
  {
    linkText: "Swim Sync Overview: Features and Benefits",
    title: "Videos",
    link: "",
    description: "",
  },
  {
    linkText: "Administrator's Walk-through: Setting Up Your School",
    title: "Videos",
    link: "",
    description: "",
  },
  {
    linkText: "Instructor's Guide: Managing Your Daily Schedule",
    title: "Videos",
    link: "",
    description: "",
  },
  {
    linkText: "Parents: How to Track Your Child's Progress",
    title: "Videos",
    link: "",
    description: "",
  },
  {
    linkText: "Students: Navigating Your Personal Dashboard",
    title: "Videos",
    link: "",
    description: "",
  },
  {
    linkText: "Mobile App Tutorial: Swim Sync on the Go",
    title: "Videos",
    link: "",
    description: "",
  },
  {
    linkText: "Advanced Features: Data Analytics for Swim Schools",
    title: "Videos",
    link: "",
    description: "",
  },
];

import "./desktop.scss";

import AppTooltip from "components/AppToolTip";
import { memo, useEffect } from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

interface Props {
  isToggle?: boolean;
}

const Pricing = ({ isToggle }: Props) => {
  const navigate = useNavigate();

  const data = [
    {
      id: 1,
      title: "One Simple Price",
      price: "$299",
      subPrice: "/mo/location",
      note: "All features included. No hidden costs.",
      btnText: "Get Started with SwimSync today",
    },
  ];

  const dataYearly = [
    {
      id: 1,
      title: "One Simple Price",
      price: "$3,049",
      subPrice: "/yr/location",
      note: "All features included. No hidden costs.",
      btnText: "Get Started with SwimSync today",
    },
  ];

  useEffect(() => {
    document.addEventListener("DOMContentLoaded", () => {
      const interBubble =
        document.querySelector<HTMLDivElement>(".interactive")!;
      let curX = 0;
      let curY = 0;
      let tgX = 0;
      let tgY = 0;

      function move() {
        curX += (tgX - curX) / 20;
        curY += (tgY - curY) / 20;
        interBubble.style.transform = `translate(${Math.round(
          curX
        )}px, ${Math.round(curY)}px)`;
        requestAnimationFrame(() => {
          move();
        });
      }

      window.addEventListener("mousemove", (event) => {
        tgX = event.clientX;
        tgY = event.clientY;
      });

      move();
    });
  }, []);

  return (
    <>
      <div className="pagePricing_pricing slider-container">
        <div className="gradient-background">
          <svg xmlns="http://www.w3.org/2000/svg">
            <defs>
              <filter id="goo">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation="10"
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  mode="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8"
                  result="goo"
                />
                <feBlend in="SourceGraphic" in2="goo" />
              </filter>
            </defs>
          </svg>
          <div className="gradients-container">
            <div className="g1"></div>
            <div className="g2"></div>
            <div className="g3"></div>
            <div className="g4"></div>
            <div className="g5"></div>
            <div className="interactive"></div>
          </div>
        </div>
        {/* <video
          autoPlay
          muted
          loop
          disablePictureInPicture
          controlsList="nodownload"
        >
          <source src="/vids/PricingBackgrounVid.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}

        <div className="pagePricing_pricing_content">
          {(isToggle ? dataYearly : data).map((item, key) => (
            <div className="pagePricing_pricing__wrapper__item" key={key}>
              <div className="pagePricing_pricing__wrapper__item__title">
                {item.title}
              </div>

              <div className="pagePricing_pricing__wrapper__item__price">
                {item.price}{" "}
                <span className="pagePricing_pricing__wrapper__item__subPrice">
                  {item.subPrice}
                </span>
              </div>

              <div className="pagePricing_pricing__wrapper__item__note">
                {item.note}
              </div>

              {item?.btnText ? (
                <div className="pagePricing_pricing__wrapper__item__button">
                  <button
                    className="text"
                    onClick={() => navigate("/interested")}
                  >
                    {item.btnText}
                  </button>
                </div>
              ) : null}
            </div>
          ))}

          <div className="pagePricing_pricing__footer">
            <div className="text1">
              All our plans have a one-time setup fee:{" "}
              <span className="highlight1">$899 per location</span>{" "}
              <AppTooltip
                tooltipText={
                  <div className="tt_text">
                    Our comprehensive setup process includes:
                    <ul>
                      <li>Software installation and configuration</li>
                      <li>Custom branding setup</li>
                      <li>Integration with your existing tools</li>
                      <li>Initial staff training session</li>
                      <li>Personalized onboarding support</li>
                    </ul>
                  </div>
                }
                position="top"
                typeToolTipText="max"
              >
                <HiOutlineExclamationCircle size={20} />
              </AppTooltip>
            </div>

            <div className="text2">
              Ensuring a smooth start for your swim school
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Pricing);

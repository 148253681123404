export const resourceCards = [
  {
    title: "Administrator's Handbook",
    linkText: "Managing Your Swimming School",
    link: "",
    description: "",
  },
  {
    title: "Administrator's Handbook",
    linkText: "Instructor Onboarding and Management",
    description: "",
  },
  {
    title: "Administrator's Handbook",
    linkText: "Financial Reporting and Analytics",
    description: "",
  },
];

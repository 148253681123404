export const highlightText = (text?: string, query?: string) => {
  if (!query || !text) return text;

  const parts = text.split(new RegExp(`(${query})`, "gi"));

  return parts.map((part, index) =>
    part.toLowerCase() === query.toLowerCase() ? (
      <span key={index} className="c__appHighlightText">
        {part}
      </span>
    ) : (
      part
    )
  );
};

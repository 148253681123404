export const resourceCards = [
  {
    title: "For Administrators",
    linkText: "Administrator's Handbook",
    link: "/user-guides-tutorials/administrators-handbook",
    description: "",
  },
  {
    title: "For Instructors",
    linkText: "Instructor's Toolkit",
    link: "/user-guides-tutorials/instructors-toolkit",
    description: "",
  },
  {
    title: "For Parents",
    linkText: "Parent's Guide",
    description: "",
  },
  {
    title: "For Students",
    linkText: "Student's Companion",
    description: "",
  },
];

export const tutorialCards = [
  {
    title: "Tutorials",
    linkText: "How to Schedule a New Class Series",
    link: "/schedule-new-class",
    description: "",
  },
  {
    title: "Tutorials",
    linkText: "Creating and Managing Student Groups",
    description: "",
  },
  {
    title: "Tutorials",
    linkText: "Generating and Interpreting Progress Reports",
    description: "",
  },
  {
    title: "Tutorials",
    linkText: "Using Swim Sync's Messaging System",
    description: "",
  },
  {
    title: "Tutorials",
    linkText: "Customizing Your Dashboard for Efficiency",
    description: "",
  },
  {
    title: "Tutorials",
    linkText: "Managing Notifications and Reminders",
    description: "",
  },
];

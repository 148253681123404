import "./desktop.scss";

import Footer from "components/footer";
import Header from "components/header/Header";
import { memo, useCallback, useState } from "react";
import { blogList } from "./const";
import { HiArrowRight, HiXMark } from "react-icons/hi2";
import CryptoJS from "crypto-js";
import AppModal from "components/AppModal";
import { RiErrorWarningFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const Article = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    },
    []
  );

  const generateHmacHash = (email: string, timestamp: number) => {
    const secretKey = "swimsynlandingpage";
    const message = `${email}:${timestamp}`;
    const hmac = CryptoJS.HmacSHA256(message, secretKey);
    return hmac.toString(CryptoJS.enc.Hex);
  };

  const handleSubmit = useCallback(async () => {
    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
    const timestamp = Date.now();
    const hash = generateHmacHash(email, timestamp);
    try {
      const response = await fetch(
        `https://swim-sync-api.advancer.asia/api/v1/landing-pages/send-welcome-email-to-subscriber`,
        {
          method: "POST",
          body: JSON.stringify({
            email,
            hash,
            timestamp,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      if (result.data) {
        setOpenModalSuccess(true);
        setEmail("");
      } else {
        setOpenModalError(true);
        setErrorMessage(result.message);
      }
    } catch (error) {
      console.error("error: ", error);
    }
  }, [email]);

  const handleNavigateLink = (link: string) => {
    navigate(link);
  };

  return (
    <>
      {/* Success Modal */}
      <AppModal
        open={openModalSuccess}
        onClose={() => setOpenModalSuccess(false)}>
        <div className="receiveMailModal">
          <div className="header">
            <img src="/images/receive-mail.png" alt="" />
            <div
              className="loginModal-header__close"
              onClick={() => setOpenModalSuccess(false)}>
              <HiXMark size={24} />
            </div>
          </div>
          <div className="content">
            Thank you for subscribing! Check your inbox for your Splash of
            Welcome!
          </div>
        </div>
      </AppModal>

      {/* Error Modal */}

      <AppModal
        open={openModalError}
        onClose={() => setOpenModalSuccess(false)}>
        <div className="receiveMailModal">
          <div className="header">
            <RiErrorWarningFill size={54} className="icon" />
            <div
              className="loginModal-header__close"
              onClick={() => setOpenModalError(false)}>
              <HiXMark size={24} />
            </div>
          </div>
          <div className="content">{errorMessage}</div>
        </div>
      </AppModal>

      <div className="articlePage">
        <Header autoScrolled />

        <div className="articlePage-wrapper">
          <button
            className="articlePage-breadcrumb"
            onClick={() => handleNavigateLink("/case-studies")}>
            <p>Case Studies</p>
          </button>

          <div className="articlePage-banner">
            <div className="banner-content">
              <img
                width={136}
                height={43}
                src="/images/caseStudyLogos2.png"
                alt="logo"
              />

              <h1>
                Wokfies developed a program to offer affordable swim lessons
                through partnerships with local schools, community centers, and
                parks
              </h1>

              <div className="banner-info">
                <div className="banner-info-item">
                  <div className="item">
                    <h3>1000</h3>
                    <p>Children participated</p>
                  </div>

                  <div className="item">
                    <h3>49%</h3>
                    <p>Increase in enrollment</p>
                  </div>
                </div>

                <div className="banner-info-item">
                  <div className="item">
                    <h5>Industry</h5>
                    <p>Swim Instruction</p>
                  </div>

                  <div className="item">
                    <h5>Location</h5>
                    <p>Australia</p>
                  </div>
                </div>
              </div>
            </div>

            <img
              className="banner-image"
              width={407}
              height={412}
              src="/images/caseStudies2.jpg"
              alt="logo"
            />
          </div>

          <div className="articlePage-content">
            <p>
              Running a swim school is like coordinating a synchronized swim
              routine—it takes planning, precision, and the right tools to flow
              smoothly. While planning important role in delivering a successful
              swim school experience.
            </p>

            <p>
              In this post, we dive into five proven strategies to boost your
              swim school’s efficiency, helping you manage everything from
              scheduling to billing.
            </p>

            <h5>1. Streamline Scheduling with Digital Tools</h5>

            <p>
              Running a swim school can turn chaotic when you can’t manage your
              scheduling process. With just a few clicks, you can automate class
              schedules, notify parents of changes through SMS, email, or push
              notifications, and track attendance. This eliminates the headache
              of last-minute cancellations and ensures that lessons run like
              clockwork, saving you time and reducing scheduling errors.
            </p>

            <h5>2. Automate Billing and Payments</h5>

            <p>
              Say goodbye to chaotic payments! Automating your billing and
              payment systems is a game-changer for swim school efficiency.
              Platforms like Swim Sync can help you set up automatic payments,
              ensuring a steady cash flow and reducing the risk of missed
              payments or confusion. With integrated payment solutions, you can
              streamline the entire billing process, giving you more time to
              focus on growing your swim school.
            </p>

            <h5>3. Centralize Communication for Smooth Sailing</h5>

            <p>
              Keeping parents informed is key to running a smooth swim school,
              but managing communication across multiple channels can be
              overwhelming. By centralizing all communication through a platform
              like Swim Sync, you can send updates via SMS, email notifications,
              and push notifications—all in one place. Whether it’s class
              changes, swim reports, or upcoming events, centralized
              communication helps ensure that no message is missed and keeps
              parents actively engaged.
            </p>

            <h5>4. Track Progress and Share Feedback Efficiently</h5>

            <p>
              Tracking student progress is vital, but it doesn’t have to be
              time-consuming. With Swim Sync’s digital progress tracking tools,
              instructors can record each swimmer’s milestones in real-time.
              Parents can log into their accounts to view progress reports and
              receive feedback, keeping them informed without the need for
              endless paperwork. This saves instructors valuable time, increases
              transparency, and fosters a stronger partnership with parents.
            </p>

            <h5>5. Boost Instructor Efficiency with Staff Management Tools</h5>

            <p>
              Staff management should be focused on teaching, not logistics.
              Swim Sync’s staff management tools allow you to efficiently assign
              tasks, track schedules, and monitor performance. By streamlining
              the way you manage your team, you can ensure that instructors are
              spending their time where it counts—helping students learn to
              swim.
            </p>

            <h5>Swim School Efficiency Starts with Smart Tools</h5>

            <p>
              Improving the efficiency of your swim school doesn’t have to be a
              challenge. By leveraging digital tools and strategies like
              instructor scheduling, centralized communication, and streamlined
              billing, you can save time, reduce administrative burdens, and
              create a better experience for both instructors and families.
              Implementing these five strategies ensures that your focus remains
              where it truly matters: teaching your students to excel in the
              water.
            </p>
          </div>

          <div className="articlePage-recent">
            <h2>Recent Case Studies</h2>

            <div className="articlePage-blog">
              <div className="blogList">
                {blogList.splice(0, 3)?.map((setupInstruction, key) => (
                  <div key={key} className="item">
                    <img
                      className="titleImage"
                      src={setupInstruction?.titleImage}
                      alt={setupInstruction?.titleImage}
                    />

                    <p>{setupInstruction?.description}</p>

                    <button className="btnWrap">
                      <p>{setupInstruction?.linkText}</p>

                      <HiArrowRight />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="articlePage-requestContent">
            <div className="content">
              <h1>
                Ready to elevate your swim school management to Olympic levels?
                Experience the Swim Sync difference today!
              </h1>

              <div className="input__wrapper">
                <input
                  type="email"
                  placeholder="Email Address"
                  onChange={handleEmailChange}
                  value={email}
                />
                <button className="btn" onClick={handleSubmit}>
                  Subscribe Now
                </button>
              </div>

              {emailError ? (
                <div className="error-message">
                  Please enter a valid email address
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default memo(Article);

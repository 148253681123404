import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote"],
  ["link", "image",],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ["clean"], // remove formatting button
];

const textEditorModule = {
  toolbar: toolbarOptions,
};
const TYPE_OPTIONS = [
  {
    name: "Select an option",
    value: "",
  },
  {
    name: "Question",
    value: "question",
  },
  {
    name: "Incident",
    value: "incident",
  },
  {
    name: "Problem",
    value: "problem",
  },
  {
    name: "Feature Request",
    value: "feature-request",
  },
];

interface ISubmitTicketModalProps {
  open: boolean;
  onClose: () => void;
}

const SubmitTicketModal = (props: ISubmitTicketModalProps) => {
  const { open, onClose } = props;

  const [loading, setLoading] = useState<boolean>(false);

  const [emailAddress, setEmailAddress] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [type, setType] = useState<string>("");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      await fetch(
        `https://crmdev-api.tego.global/api/v1/activity-stream/hooks/webhook-trigger/6a4faa71-48d3-44ef-a3a5-2a5a4c09c633`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            emailAddress,
            subject,
            content,
            type,
          }),
        }
      );
      setEmailAddress("");
      setSubject("");
      setContent("");
      setType("");
      onClose();
    } catch (error) {
      console.error("error: ", error);
    }
    setLoading(false);
  };

  return (
    <div className={`submitTicketModal ${open ? "open" : ""}`}>
      <div className="submitTicketModal__overlay"></div>
      <div className="submitTicketModal__content">
        <div className="submitTicketModal__content__header">
          <p>Submit a ticket</p>
          <div
            className="submitTicketModal__content__header--icon"
            onClick={onClose}
          >
            <img src="/icons/x-mark.png" alt="close" />
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          className="submitTicketModal__content__main"
        >
          <div className="inputField">
            <label htmlFor="emailAddress">Email Address</label>
            <input
              id="emailAddress"
              type="email"
              placeholder="Enter your email"
              value={emailAddress}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setEmailAddress(event.target.value)
              }
              required
            />
          </div>
          <div className="inputField">
            <label htmlFor="subject">Subject</label>
            <input
              id="subject"
              type="text"
              placeholder="Enter subject"
              value={subject}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSubject(event.target.value)
              }
              required
            />
          </div>
          <div className="c__app__ckEditor">
            <ReactQuill
              modules={textEditorModule}
              theme="snow"
              placeholder="Please type your text here"
              value={content}
              onChange={(value: string) => setContent(value)}
            />
          </div>
          <div className="inputField">
            <label htmlFor="type">Type</label>
            <select
              name="type"
              id="type"
              value={type}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                setType(event.target.value)
              }
              required
            >
              {TYPE_OPTIONS.map((option) => {
                return (
                  <option value={option.value} key={option.value}>
                    {option.name}
                  </option>
                );
              })}
            </select>
          </div>
          <button disabled={loading}>
            {loading ? "Loading..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SubmitTicketModal;

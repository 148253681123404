export const setupInstructions = [
  {
    title: "Set Up School Profile",
    description:
      "Personalize the system with your school's details for easy reference and communication.",
    steps: [
      "Once logged in, navigate to the Settings tab and select School Profile.",
      {
        text: "Fill in essential details such as:",
        list: ["School Name", "Address", "Phone Number", "Email Address"],
      },
      "Upload your school logo and cover photo (optional, but recommended for branding).",
      "Save the changes.",
    ],
  },
  {
    title: "Add Instructor Profile",
    description:
      "Set up your instructors so they can manage classes, student progress, and attendance.",
    steps: [
      "Go to the Instructors section under the Staff Management tab.",
      "Click Add Instructor.",
      {
        text: "Enter details for each instructor:",
        list: [
          "Name",
          "Email",
          "Phone Number",
          "Certification and Qualifications",
        ],
      },
      "Assign instructors to specific classes or groups.",
      "Save and repeat the process for each instructor.",
    ],
  },
  {
    title: "Set Up Classes and Schedules",
    description:
      "Organize classes, set schedules, and determine the type of swimming lessons (group, private, beginner, advanced, etc.).",
    steps: [
      "Go to the Classes section from the dashboard.",
      "Click Add New Class",
      {
        text: "Fill in the class details:",
        list: [
          "Class Name",
          "Instructor (assigned earlier)",
          "Schedule (days of the week and time)",
          "Level (e.g., Beginner, Intermediate, Advanced)",
          "Max Students",
          "Class Type (e.g., Group, Private)",
        ],
      },
      "Click Save.",
      "Repeat for all classes you wish to offer.",
      "Optionally, set up Recurring Classes to automatically add weekly or monthly classes.",
    ],
  },
  {
    title: "Add Students",
    description:
      "Enroll students in your swimming school and assign them to classes.",
    steps: [
      "Go to the Students section.",
      "Click Add New Student.",
      {
        text: "Enter the student's details:",
        list: [
          "Name",
          "Date of Birth",
          "Emergency Contact",
          "Health Information (if necessary)",
        ],
      },
      "Select the classes or sessions the student will attend.",
      "Save the student’s profile and repeat the process for other students.",
    ],
  },
  {
    title: "Set Up Payment and Billing System",
    description:
      "Communicate with parents, students, and instructors through the system.",
    steps: [
      "Go to the Communication section.",
      "Configure Email Notifications for class reminders, payment due dates, and announcements.",
      "Enable SMS Notifications if supported by the system.",
      "Set up a Message Board for school-wide announcements or specific class notifications.",
      "Ensure that each instructor can message students regarding class updates.",
    ],
  },
  {
    title: "Customize Reports and Analytics",
    description:
      "Monitor class attendance, student progress, and financial transactions.",
    steps: [
      "Go to the Reports section.",
      {
        text: "Choose the types of reports you want to track:",
        list: [
          "Attendance Reports: Track class attendance for each student.",
          "Progress Reports: Monitor each student’s swimming development (e.g., skill assessments, improvements).",
          "Financial Reports: View payments, outstanding balances, and overall revenue.",
        ],
      },
      "Customize the report frequency (e.g., weekly, monthly).",
      "Download or email the reports as needed.",
    ],
  },
  {
    title: "Set Up User Roles and Permissions",
    description:
      "Control access to different parts of the system based on user roles (e.g., admins, instructors, students).",
    steps: [
      "Navigate to User Roles under Settings.",
      {
        text: "Define roles for each user type:",
        list: [
          "Admin: Full access to the system.",
          "Instructor: Access to student information, class management, and attendance.",
          "Student/Parent: Limited access, such as viewing schedules and making payments.",
        ],
      },
      "Assign each user to the appropriate role and save.",
    ],
  },
  {
    title: "Review and Test",
    description: "Ensure everything is set up correctly before going live.",
    steps: [
      "Test student enrollments by adding a student and assigning them to a class.",
      "Check if payments are processed correctly.",
      "Verify communication channels (e.g., send a test email or SMS).",
      "Confirm class schedules appear accurately for both instructors and students.",
      "Ensure all reports are accessible and functioning properly.",
    ],
  },
];

import "./desktop.scss";

import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { highlightText } from "assets/ultils/highlightText";
import {
  DEFAULT_ACTIVE_MENU,
  DEFAULT_ACTIVE_SUBMENU,
  DEFAULT_MENU_ACTIVE_SUBMENU,
} from "./const";
import { HiChevronDown, HiChevronUp } from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";

interface sidebarSubMenu {
  title: string;
  link: string;
  icon?: string;
}

export interface sidebarMenu {
  title: string;
  link: string;
  icon?: string;
  subMenus?: sidebarSubMenu[];
}

type sideMenuType = "menu" | "content";

interface Props {
  sideBarMenus: sidebarMenu[];
  type?: sideMenuType;
  searchQuery?: string;
  isNavigate?: boolean;

  onChange?: (link: string) => void;
}

const SideMenu = ({
  sideBarMenus,
  type = "menu",
  searchQuery = "",
  isNavigate = false,
  onChange,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeMenu, setActiveMenu] = useState(DEFAULT_ACTIVE_MENU);
  const [activeSubMenu, setActiveSubMenu] = useState(DEFAULT_ACTIVE_SUBMENU);

  const [currentActiveSubMenu, setCurrentActiveSubMenu] = useState(
    DEFAULT_MENU_ACTIVE_SUBMENU
  );

  const handleNavigateLink = (link: string) => {
    navigate(link);
  };

  const handleChangeActiveMenu = (key: number, sideBarMenu?: sidebarMenu) => {
    setActiveMenu(key);

    if (sideBarMenu?.link) {
      if (isNavigate && sideBarMenu?.link !== location.pathname)
        handleNavigateLink(sideBarMenu?.link);

      if (onChange) onChange(sideBarMenu?.link);
    }
  };

  const handleChangeActiveSubMenu = useCallback(
    (subMenuKey: number, sidebarSubMenu?: sidebarSubMenu) => {
      setActiveSubMenu(subMenuKey);

      if (sidebarSubMenu?.link) {
        if (isNavigate && sidebarSubMenu?.link !== location.pathname)
          handleNavigateLink(sidebarSubMenu?.link);

        if (onChange) onChange(sidebarSubMenu?.link);
      }
    },

    // stop watch for onChange
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleChangeCurrentActiveSubMenu = (
    hasSubMenu: boolean,
    menuKey: number
  ) => {
    if (hasSubMenu) {
      setCurrentActiveSubMenu((pre) =>
        pre === menuKey ? DEFAULT_MENU_ACTIVE_SUBMENU : menuKey
      );
      return;
    }

    setCurrentActiveSubMenu(DEFAULT_MENU_ACTIVE_SUBMENU);
  };

  const handleClickMenu = (key: number, sideBarMenu?: sidebarMenu) => {
    handleChangeActiveMenu(key, sideBarMenu);

    handleChangeCurrentActiveSubMenu(!!sideBarMenu?.subMenus?.length, key);
  };

  const __showSubMenu = useMemo(() => {
    return currentActiveSubMenu >= 0;
  }, [currentActiveSubMenu]);

  useEffect(() => {
    const activeMenuKey = sideBarMenus.findIndex((sideBarMenu) =>
      location.pathname?.includes(sideBarMenu?.link)
    );

    if (activeMenuKey >= 0) {
      setActiveMenu(activeMenuKey);

      const hasSubMenu = sideBarMenus.find((sideBarMenu) =>
        location.pathname?.includes(sideBarMenu?.link)
      )?.subMenus;

      if (hasSubMenu) {
        const activeSubMenuKey = hasSubMenu.findIndex((sideBarMenu) =>
          location.pathname?.includes(sideBarMenu?.link) && sideBarMenu?.link
        );

        if (activeSubMenuKey >= 0) {
          setCurrentActiveSubMenu(activeSubMenuKey);

          setActiveSubMenu(activeSubMenuKey);
        }
      }
    }
  }, [sideBarMenus, location]);

  if (type === "menu")
    return (
      <div className="c__appSidebar">
        {sideBarMenus?.map((sideBarMenu, key) => (
          <Fragment key={key}>
            <button
              className={`sidebarItem ${
                activeMenu === key ? "activeMenu" : ""
              }`}
              onClick={() => handleClickMenu(key, sideBarMenu)}>
              {sideBarMenu?.icon ? (
                <img src={sideBarMenu?.icon} alt={sideBarMenu?.title} />
              ) : null}
              <p>{highlightText(sideBarMenu?.title, searchQuery)}</p>

              {sideBarMenu?.subMenus?.length ? (
                __showSubMenu ? (
                  <HiChevronUp className="chevronDown" size={20} />
                ) : (
                  <HiChevronDown className="chevronDown" size={20} />
                )
              ) : null}
            </button>

            {__showSubMenu && sideBarMenu?.subMenus?.length ? (
              <ul className={`dropdownMenu ${__showSubMenu ? "open" : ""}`}>
                {sideBarMenu?.subMenus.map((subMenu, subMenuKey) => (
                  <li key={subMenuKey} className="dropdownSubMenu">
                    <button
                      className={`sidebarItem ${
                        activeSubMenu === subMenuKey ? "activeMenu" : ""
                      }`}
                      onClick={() =>
                        handleChangeActiveSubMenu(subMenuKey, subMenu)
                      }>
                      {subMenu?.icon ? (
                        <img src={subMenu?.icon} alt={subMenu?.title} />
                      ) : null}
                      <p>{highlightText(subMenu?.title, searchQuery)}</p>
                    </button>
                  </li>
                ))}
              </ul>
            ) : null}
          </Fragment>
        ))}
      </div>
    );

  return (
    <div className="c__appSidebar">
      <div className="appSideBarLabel">
        {highlightText("Table of contents", searchQuery)}
      </div>

      {sideBarMenus?.map((sideBarMenu, key) => (
        <button
          key={key}
          className={`sidebarContentItem ${
            activeMenu === key ? "activeMenu" : ""
          }`}
          onClick={() => handleChangeActiveMenu(key, sideBarMenu)}>
          {sideBarMenu?.icon ? (
            <img src={sideBarMenu?.icon} alt={sideBarMenu?.title} />
          ) : null}
          <p>{highlightText(sideBarMenu?.title, searchQuery)}</p>
        </button>
      ))}
    </div>
  );
};

export default memo(SideMenu);

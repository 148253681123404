export const resourceCards = [
  {
    title: "Instructor's Toolkit",
    linkText: "Class Management and Scheduling",
    link: "",
    description: "",
  },
  {
    title: "Instructor's Toolkit",
    linkText: "Student Progress Tracking",
    description: "",
  },
  {
    title: "Instructor's Toolkit",
    linkText: "Communication Tools for Instructors",
    description: "",
  },
];

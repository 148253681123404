export const resourceCards = [
  {
    title: "For Administrators",
    linkText: "Quick Setup Guide",
    link: "/quick-set-up-guide",
    description:
      "Learn how to set up your school’s account, add instructors, and configure your scheduling preferences.",
  },
  {
    title: "For Instructors",
    linkText: "Your First Day with Swim Sync",
    description:
      "Discover how to access your schedule, manage your classes, and track student progress.",
  },
  {
    title: "For Parents",
    linkText: "Enrolling Your Child",
    description:
      "Step-by-step guide on how to create an account, browse classes, and enroll your child in swimming lessons.",
  },
  {
    title: "For Students",
    linkText: "Accessing Your Swim Schedule",
    description:
      "Learn how to view your upcoming lessons, track your progress, and communicate with your instructor.",
  },
];

export const listFAQs = [
  {
    question: "How do I reset my password?",
    answer: [
      {
        description:
          "Swim Sync does not require a password reset! Instead, we use a secure OTP (One-Time Password) system for quick and safe access.",
      },
      {
        title: "Steps to log in with OTP:",
        steps: [
          "Go to the Swim Sync login page.",
          "Enter your registered email address.",
          "Check your email for the OTP code we sent you.",
          "Enter the OTP on the login screen to access your account.",
        ],
      },
      {
        note: "Each OTP is valid for a limited time to ensure security. If your code expires, simply request a new one.",
      },
    ],
  },
  {
    question: "Can I change my child's enrolled class?",
    answer: [
      "Yes! Log in to your Swim Portal and navigate to the Enrollment section.",
      "Select your child’s current class and click Move Enrollment.",
      "Choose the new class and follow the next steps to complete the process.",
      {
        note: "Changes depend on class availability. If you need assistance, contact our support team.",
      },
    ],
  },
  {
    question: "What payment methods does Swim Sync accept?",
    answer: [
      "We accept credit cards, debit cards, and all major payment methods through Stripe for secure payments.",
      "Pay as you go: Payments are made on a fixed cycle set by the school.",
    ],
  },
  {
    question: "How can I view my billing history?",
    answer: [
      "Log in to your Swim Portal and go to the Billing section.",
      "You can view past payments, upcoming charges, and download invoices as needed.",
    ],
  },
  {
    question: "Is my personal information secure with Swim Sync?",
    answer: [
      "Absolutely! Swim Sync uses SSL encryption and is integrated with Stripe, a PCI-compliant payment processor.",
      "We never store sensitive payment information in our system—only authorized staff can process payments.",
    ],
  },
  {
    question: "How do I contact customer support?",
    answer: [
      {
        title: "You can reach us via:",
        steps: ["Email: support@swimsync.com", "Phone: (XXX) XXX-XXXX"],
      },
    ],
  },
  {
    question: "Can multiple family members use the same account?",
    answer: [
      "Yes! Multiple family members can share the same account.",
      "Parents can manage all children’s enrollments, billing, and progress reports from one convenient portal.",
    ],
  },
  {
    question: "How often are student progress reports updated?",
    answer: [
      "Progress reports are updated weekly and are available in the Swim Portal.",
      "You’ll also receive notifications for major milestones and achievements.",
    ],
  },
];

export const setupInstructions = [
  {
    titleImage: "/images/caseStudies1.jpg",
    logo: "/images/caseStudyLogos1.png",
    linkText: "Learn more",
    link: "/article",
    description:
      "Wokfies developed a program to offer affordable swim lessons through partnerships with local schools, community centers, and parks.",
  },
  {
    titleImage: "/images/caseStudies2.jpg",
    logo: "/images/caseStudyLogos2.png",
    linkText: "Learn more",
    link: "/article",
    description:
      "EcoSwim focused on creating eco-friendly swimming supplies, such as plastic bottles and sustainable manufacturing processes.",
  },
  {
    titleImage: "/images/caseStudies3.jpg",
    logo: "/images/caseStudyLogos3.png",
    linkText: "Learn more",
    link: "/article",
    description:
      "We provided our [Product Name] with UV-protection and chlorine-resistant fabric, making it ideal for both casual swimmers and competitive athletes",
  },
  {
    titleImage: "/images/caseStudies4.jpg",
    logo: "/images/caseStudyLogos4.png",
    linkText: "Learn more",
    link: "/article",
    description:
      "Our suits use [specific material, such as chlorine-resistant Lycra or eco-friendly fabric] that lasts longer, prevents fading, and resists stretching",
  },
  {
    titleImage: "/images/caseStudies5.jpg",
    logo: "/images/caseStudyLogos5.png",
    linkText: "Learn more",
    link: "/article",
    description:
      "We worked with the client to understand their needs, chose the appropriate swimwear materials, and delivered a custom order within 2 weeks",
  },
];

export const blogList = [
  {
    titleImage: "/images/caseStudies4.jpg",
    linkText: "Read more",
    link: "/article",
    description: "5 Ways to Improve Swim School Efficiency",
  },
  {
    titleImage: "/images/caseStudies5.jpg",
    linkText: "Read more",
    link: "/article",
    description: "Maximizing Parent Engagement in Swim Schools",
  },
  {
    titleImage: "/images/caseStudies2.jpg",
    linkText: "Read more",
    link: "/article",
    description: "The Impact of Technology on Swim Instruction",
  },
  {
    titleImage: "/images/caseStudies5.jpg",
    linkText: "Read more",
    link: "/article",
    description: "5 Ways to Improve Swim School Efficiency",
  },
  {
    titleImage: "/images/caseStudies4.jpg",
    linkText: "Read more",
    link: "/article",
    description: "Maximizing Parent Engagement in Swim Schools",
  },
  {
    titleImage: "/images/caseStudies3.jpg",
    linkText: "Read more",
    link: "/article",
    description: "The Impact of Technology on Swim Instruction",
  },
];

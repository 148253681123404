import "./desktop.scss";

import { memo, useCallback, useState } from "react";
import Header from "components/header/Header";
import Footer from "components/footer";
import { HiMagnifyingGlass, HiXMark } from "react-icons/hi2";
import SideMenu from "components/SideMenu";
import { resourceCards } from "./const";
import { highlightText } from "assets/ultils/highlightText";
import { useNavigate } from "react-router-dom";
import { sideBarMenus } from "assets/common/resources";

const InstructorsToolkit = () => {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value);
    },
    []
  );

  const handleResourceLink = (key: number, link?: string) => {
    if (link) navigate(link);
  };

  return (
    <div className="instructorsToolkitPage">
      <Header autoScrolled />

      {/* Header Section */}
      <div className="instructorsToolkitPage-wrapper">
        <div className="instructorsToolkitPage-headerWrapper">
          <div className="headerTitle">
            {highlightText("Hello, How can we help?", searchQuery)}
          </div>
          <div className="instructorsToolkitPage-input">
            <HiMagnifyingGlass size={20} />
            <input
              aria-label="Search input"
              placeholder="Search using keyword..."
              value={searchQuery}
              onChange={handleInputChange}
            />

            <HiXMark
              size={20}
              className={searchQuery ? "showCancel" : "removeCancel"}
              onClick={() => setSearchQuery("")}
            />
          </div>
        </div>
      </div>

      <div className="instructorsToolkitPage-mainContent">
        <SideMenu
          isNavigate
          sideBarMenus={sideBarMenus}
          searchQuery={searchQuery}
        />

        <main className="instructorsToolkitPage-content">
          <header className="instructorsToolkitPage-contentHeader">
            <div className="headerWrap">
              <h1>{highlightText("Instructor's Toolkit", searchQuery)}</h1>
            </div>
          </header>
          <section className="instructorsToolkitPage-resourceCards">
            {resourceCards.map((card, key) => (
              <button
                key={key}
                className="instructorsToolkitPage-resourceCard"
                onClick={() => handleResourceLink(key, card?.link)}>
                <div className="instructorsToolkitPage-resourceLink">
                  {highlightText(card.linkText, searchQuery)}
                </div>
                <p>{highlightText(card.description, searchQuery)}</p>
              </button>
            ))}
          </section>
        </main>
      </div>

      <Footer />
    </div>
  );
};

export default memo(InstructorsToolkit);

import FeatureStaffPortal from "pages/features/StaffPortal";
import FeatureMemberPortal from "pages/features/MemberPortal";
import AdministrativeTools from "pages/features/AdministrativeTools";
import Pricing from "pages/pricing";
import { IRoute } from "../interfaces";
import Home from "pages/home";
import IntegrationNSecurity from "pages/features/IntegrationNSecurity";
import DataPrivacy from "pages/dataPrivacy";
import TermCondition from "pages/termCondition";
import Interested from "pages/interested";
import WaysImprove from "pages/blogs/temps/WaysImprove";
import MaximizingParentEngagement from "pages/blogs/temps/MaximizingParentEngagement";
import ImpactOfTechnology from "pages/blogs/temps/ImpactOfTechnology";
import WaterSafetyEducation from "pages/blogs/temps/WaterSafetyEducation";
import SeamlessSwimSchool from "pages/blogs/temps/SeamlessSwimSchool";
import ReportNAnalytics from "pages/features/ReportNAnalytics";
import Contact from "pages/contact";
import About from "pages/about";
import Blogs from "pages/blogs";
import GettingStarted from "pages/gettingStarted";
import QuickSetupGuide from "pages/quickSetupGuide";
import UserGuides from "pages/userGuides";
import AdministratorsHandbook from "pages/administratorsHandbook";
import InstructorsToolkit from "pages/instructorsToolkit";
import ScheduleNewClass from "pages/scheduleNewClass";
import Videos from "pages/videos";
import DownloadableDocument from "pages/downloadableDocument";
import CaseStudies from "pages/caseStudies";
import Article from "pages/article";

export const publicIndexRoutes: IRoute[] = [
  {
    path: "/",
    component: <Home />,
    isPublic: true,
  },
  {
    path: "*",
    component: <Home />,
    isPublic: true,
  },
  {
    path: "/feature/staff",
    component: <FeatureStaffPortal />,
    isPublic: true,
  },
  {
    path: "/feature/member",
    component: <FeatureMemberPortal />,
    isPublic: true,
  },
  {
    path: "/feature/administrative-tools",
    component: <AdministrativeTools />,
    isPublic: true,
  },
  {
    path: "/feature/reporting-and-analytics",
    component: <ReportNAnalytics />,
    isPublic: true,
  },
  {
    path: "/feature/integration-and-security",
    component: <IntegrationNSecurity />,
    isPublic: true,
  },
  {
    path: "/pricing",
    component: <Pricing />,
    isPublic: true,
  },
  {
    path: "/about",
    component: <About />,
    isPublic: true,
  },
  {
    path: "/contact",
    component: <Contact />,
    isPublic: true,
  },
  {
    path: "/interested",
    component: <Interested />,
    isPublic: true,
  },
  {
    path: "/data-privacy",
    component: <DataPrivacy />,
    isPublic: true,
  },
  {
    path: "/terms-and-conditions",
    component: <TermCondition />,
    isPublic: true,
  },
  {
    path: "/blogs",
    component: <Blogs />,
    isPublic: true,
  },
  {
    path: "/blogs/ways-improve-swim-school-efficiency",
    component: <WaysImprove />,
    isPublic: true,
  },
  {
    path: "/blogs/maximizing-parent-engagement",
    component: <MaximizingParentEngagement />,
    isPublic: true,
  },
  {
    path: "/blogs/the-impact-of-technology-on-swim-instruction",
    component: <ImpactOfTechnology />,
    isPublic: true,
  },
  {
    path: "/blogs/why-water-safety-education-should-be-at-the-core-of-every-swim-schools-mission",
    component: <WaterSafetyEducation />,
    isPublic: true,
  },
  {
    path: "/blogs/creating-a-seamless-swim-school-experience-how-to-make-every-lesson-count",
    component: <SeamlessSwimSchool />,
    isPublic: true,
  },
  {
    path: "/getting-started-faqs",
    component: <GettingStarted />,
    isPublic: true,
  },
  {
    path: "/quick-set-up-guide",
    component: <QuickSetupGuide />,
    isPublic: true,
  },
  {
    path: "/user-guides-tutorials",
    component: <UserGuides />,
    isPublic: true,
  },
  {
    path: "/user-guides-tutorials/administrators-handbook",
    component: <AdministratorsHandbook />,
    isPublic: true,
  },
  {
    path: "/user-guides-tutorials/instructors-toolkit",
    component: <InstructorsToolkit />,
    isPublic: true,
  },
  {
    path: "/schedule-new-class",
    component: <ScheduleNewClass />,
    isPublic: true,
  },
  {
    path: "/videos",
    component: <Videos />,
    isPublic: true,
  },
  {
    path: "/downloadable-document",
    component: <DownloadableDocument />,
    isPublic: true,
  },
  {
    path: "/case-studies",
    component: <CaseStudies />,
    isPublic: true,
  },
  {
    path: "/article",
    component: <Article />,
    isPublic: true,
  },
];
